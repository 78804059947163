import * as React from 'react'
import { useSegment } from './Segment'

type Props = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  eventProperties?: object | Function
  value: any
  eventType: string
  children?: React.ReactNode
}

export const SegmentLogOnChange: React.FunctionComponent<Props> = (props: Props) => {
  const { logEvent } = useSegment()

  React.useEffect(() => {
    logEvent(props.eventType, props.eventProperties)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value])

  return props.children || (null as any)
}
