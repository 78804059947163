import '../../styles/styles.css'
import '../../styles/carousel.css'

import {useEffect, useState} from 'react'
import Head from 'next/head'
import { Toaster } from 'react-hot-toast'
import { Segment, SegmentProvider } from '../lib/segment'
import { includes } from 'ramda'
import { pascalCase } from 'change-case'
import { useRouter } from 'next/router'

export const CUSTOMER_WEBAPP_URL = process.env.CUSTOMER_WEBAPP_URL

const defaultSegmentWriteKey = 'y14RXKxfEdlmSbWR25qwCOGlAMPmgQt1'
const segmentWriteKey = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY || defaultSegmentWriteKey

const getDeviceCategory = () => {
  if (typeof window === 'undefined') return '';
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'Tablet'
  }
  if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return 'Mobile'
  }
  return 'Desktop'
}

const NuBrakesApp = ({ Component, pageProps }) => {
  const [landingPagePathname, setLandingPagePathName] = useState(null)
  const router = useRouter()

  useEffect(() => {
    setLandingPagePathName(window.location.pathname)
  }, [])

    return (
      <>
        <Head>
          <title>NuBrakes | Mobile Brake Repair</title>
          <link rel={'shortcut icon'} href={'/img/favicon.ico'} />
        </Head>
        <SegmentProvider writeKey={segmentWriteKey}>
          <Segment
            eventProperties={{
              scope: ['NuBrakes'],
              deviceCategory: getDeviceCategory(),
              landingPagePathname: landingPagePathname ?? router.asPath,
              currentPagePathname: router.asPath,
              ...Object.assign(
                {},
                ...Object.keys(router.query)
                  .filter((key) => !includes(key, ['vehicleYear', 'vehicleMake', 'vehicleModel'])) //filter YMM out of params that we feed to Segment as part of the landingPageUrl since we send them with the form events
                  .map((key) => ({
                    ['landingPageUrl' + pascalCase(key)]: router.query[key],
                  }))
              ),
            }}
          >
            <Component {...pageProps} />
          </Segment>
        </SegmentProvider>
        <Toaster />
      </>
    )
}

export default NuBrakesApp
